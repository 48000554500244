import en from '@/languages/en-US'
import tr from '@/languages/tr-TR'

export default {
  'en-US': {
    translation: en,
  },
  'tr-TR': {
    translation: tr,
  },
}
