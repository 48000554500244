import { useState } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

import MobileRootBottomSliderPopup from '@/components/MobileRootBottomSliderPopup/MobileRootBottomModal'
import UserSettings from '@/containers/Root/components/UserSettings/UserSettings'
import UserUpdatePassword from '@/containers/Root/components/UserUpdatePassword/UserUpdatePassword'

import './mobileUserSettingsModal.scss'

const MODAL_TYPES = {
  USER_SETTINGS: 'USER_SETTINGS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
}

const ModalMenu = ({ handleOnClickItem }) => {
  const { t } = useTranslation()

  const MODAL_LIST = [
    { text: t('mobileRoot.userSettings.updateProfile'), type: MODAL_TYPES.USER_SETTINGS },
    {
      text: t('mobileRoot.userSettings.changePassword'),
      type: MODAL_TYPES.CHANGE_PASSWORD,
    },
  ]

  const handleOnClick = type => () => {
    handleOnClickItem(type)
  }

  return (
    <div className='user-settings-modal__list'>
      {MODAL_LIST.map(item => (
        <div onClick={handleOnClick(item.type)} key={item.type} className='user-settings-modal__item'>
          <span className='user-settings-modal__text'>{item.text}</span>
          <IoIosArrowForward className='user-settings-modal__icon' />
        </div>
      ))}
    </div>
  )
}

const MobileUserSettingsModal = ({ isVisible, handleCloseModal: _handleCloseModal }) => {
  const { t } = useTranslation()
  const [activeModalType, setActiveModalType] = useState(null)

  const isActiveMenuList = activeModalType === null

  const handleClearActiveModalType = () => {
    setActiveModalType(null)
  }

  const handleCloseModal = () => {
    _handleCloseModal()
    handleClearActiveModalType()
  }

  const getModalTitle = () => {
    switch (true) {
      case activeModalType === MODAL_TYPES.USER_SETTINGS:
        return t('mobileRoot.userSettings.updateProfile')
      case activeModalType === MODAL_TYPES.CHANGE_PASSWORD:
        return t('mobileRoot.userSettings.changePassword')
      default:
        return ''
    }
  }

  const renderModalDynamicContent = () => {
    switch (activeModalType) {
      case MODAL_TYPES.USER_SETTINGS: {
        return <UserSettings />
      }
      case MODAL_TYPES.CHANGE_PASSWORD: {
        return <UserUpdatePassword />
      }
    }
  }

  const handleOnClickMenuItem = type => {
    setActiveModalType(type)
  }

  const renderContent = () => {
    switch (true) {
      case isActiveMenuList: {
        return <ModalMenu handleOnClickItem={handleOnClickMenuItem} />
      }
      default:
        return renderModalDynamicContent()
    }
  }

  const renderModalStrictContent = () => {
    switch (true) {
      case !isActiveMenuList:
        return <IoIosArrowBack onClick={handleClearActiveModalType} className='user-settings-modal__back-icon' />
      default:
        return ''
    }
  }

  return (
    <MobileRootBottomSliderPopup
      isVisible={isVisible}
      handleCloseModal={handleCloseModal}
      title={getModalTitle()}
      modalStrictContent={renderModalStrictContent()}>
      {renderContent()}
    </MobileRootBottomSliderPopup>
  )
}

export default MobileUserSettingsModal
