import { useTranslation } from 'react-i18next'

import MobileRootBottomSliderPopup from '@/components/MobileRootBottomSliderPopup/MobileRootBottomModal'
import ActionMenuList from '@/containers/Root/components/ActionMenuList/ActionMenuList'

import './mobileAllApisModal.scss'

const MobileAllApisModal = ({ isVisible, handleCloseModal }) => {
  const { t } = useTranslation()

  const handleClickActionItem = () => {
    handleCloseModal()
  }

  return (
    <MobileRootBottomSliderPopup isVisible={isVisible} handleCloseModal={handleCloseModal} title={t('mobileRoot.allApis.title')}>
      <ActionMenuList handleClickActionItem={handleClickActionItem} />
    </MobileRootBottomSliderPopup>
  )
}

export default MobileAllApisModal
