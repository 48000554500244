import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useTranslation } from 'react-i18next'

import useClickOutside from '@/hooks/useClickOutside'

import './colorPickerInput.scss'

export const DEFAULT_COLOR = '#000000'

const ColorPickerInput = ({ value, handleChangeValue, buttonClassName, pickerClassName }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [color, setColor] = useState(DEFAULT_COLOR)

  const pickerRef = useRef()
  const { t } = useTranslation()

  useClickOutside(pickerRef, () => {
    setIsVisible(false)
  })

  const handleOnChange = _value => {
    setColor(_value)
    handleChangeValue && handleChangeValue(_value)
  }

  const handleOnClickColorPicker = () => {
    setIsVisible(true)
  }

  const handleOnChangeColorInput = e => {
    const currentValue = e.target.value

    setColor(currentValue)
    handleChangeValue && handleChangeValue(currentValue)
  }

  useEffect(() => {
    if (!!value && value !== color) {
      setColor(value)
    }
  }, [value])

  return (
    <div ref={pickerRef} className='color-picker' onClick={handleOnClickColorPicker}>
      <div className={clsx('color-picker__button', buttonClassName, { 'color-picker__button--enable': isVisible })}>
        <input
          onChange={handleOnChangeColorInput}
          className='color-picker__color-input'
          placeholder={t('colorPicker.placeholder')}
          value={color}
        />
        <div className='color-picker__color-preview' style={{ backgroundColor: color }} />
      </div>
      <HexColorPicker
        className={clsx('color-picker__picker', pickerClassName, { 'color-picker__picker--enable': isVisible })}
        color={color}
        onChange={handleOnChange}
      />
    </div>
  )
}

export default ColorPickerInput
