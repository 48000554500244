import { BiHomeAlt2 } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'

import MenuItemList from '@/containers/Root/components/common/MenuItemList/MenuItemList'
import useActiveLocation from '@/hooks/useActiveLocation'
import { isMobile } from '@/utils'

const GeneralMenuList = ({ handleClickMenuItem }) => {
  const { t } = useTranslation()
  const { checkIsActiveLocation } = useActiveLocation()

  const MENU_LIST = [
    {
      title: t('generalMenuList.title'),
      items: [
        {
          path: '/',
          Icon: BiHomeAlt2,
          text: t('generalMenuList.home'),
          isActive: checkIsActiveLocation('/'),
        },
      ],
    },
  ]

  return <MenuItemList menuList={MENU_LIST} {...(isMobile() ? { handleClickMenuItem } : {})} />
}

export default GeneralMenuList
