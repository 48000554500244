import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import CreateProjectPopup from '../CreateProjectPopup/CreateProjectPopup'
import './noProjects.scss'

const NoProjects = () => {
  const { t } = useTranslation()
  const [isEnabledCreateProjectPopup, setIsEnabledCreateProjectPopup] = useState(false)

  const handleClosePopup = () => {
    setIsEnabledCreateProjectPopup(false)
  }

  const handleClickCreateProject = () => {
    setIsEnabledCreateProjectPopup(true)
  }

  return (
    <div className='no-projects'>
      <CreateProjectPopup isVisible={isEnabledCreateProjectPopup} handleClosePopup={handleClosePopup} />
      <div className='no-projects__text'>{t('noProjects.text')}</div>
      <PrimaryButton onClick={handleClickCreateProject} className='no-projects__create-project-btn'>
        {t('noProjects.button.create')}
      </PrimaryButton>
    </div>
  )
}

export default NoProjects
