export const ROUTE_URLS = {
  HOME: '/',
  USERS: '/users',
  ACTION_GROUP_PERMISSIONS: '/action-group-permissions',
  API_EXECUTE: '/api-execute/:id',
  PROJECT_LOGS: '/project-logs',
  PROJECT_SETTINGS: '/project-settings',
  PROJECT_USER_IMPRINT: '/project-user-imprint',
  PROJECT_USER_NOTIFICATIONS: '/project-user-notifications',
  CHARTS: '/charts',
  TABLES: '/tables',
  PROJECT_USER_PERMISSIONS: '/project-user-permissions',
  BUILD_API: '/build-api',
  POSTMAN_BUILDER: '/postman-builder',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGET_PASSWORD: '/forget-password',
  CHANGE_PASSWORD: '/change-password/:token',
  ACTIONS: '/actions',
  CHOOSE_CREATE_API_TYPE: '/choose-create-api-type',
  LOGIN_WITH_ACCOUNT: '/login-with-account',
}
