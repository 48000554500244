import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { updateMe } from '@/api'
import TurkeyLanguage from '@/assets/icons/turkey.png'
import UsaLanguage from '@/assets/icons/usa.png'
import InavoAccountBlackLogo from '@/assets/logos/inavo-account-black.svg'
import Input from '@/components/Input/Input'
import InputDropdown from '@/components/InputDropdown/InputDropdown'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import UserAvatar, { PROFILE_IMAGE_SIZES } from '@/components/UserAvatar/UserAvatar'
import { LANGUAGES, USER_REGISTER_TYPES } from '@/constants'
import UserAdvancedSettings from '@/containers/Root/components/UserSettings/UserAdvancedSettings/UserAdvancedSettings'
import { authReducerActions } from '@/store/reducers/auth'
import { getActiveProjectLanguage } from '@/utils'
import { setActiveLanguage } from '@/utils/localStorageActions'

import './userSettings.scss'

const languagesItems = [
  { label: 'English', value: LANGUAGES.EN, icon: UsaLanguage },
  { label: 'Türkçe', value: LANGUAGES.TR, icon: TurkeyLanguage },
]

const UserSettings = () => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [image, setImage] = useState(null)
  const [userDetail, setUserDetail] = useState({})
  const [language, setLanguage] = useState(getActiveProjectLanguage())

  const authUserDetail = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const handleChangeUserDetail = item => setUserDetail({ ...userDetail, ...item })

  const handleChangeImage = e => {
    const image = e.target.files[0]

    setImage(URL.createObjectURL(image))
    handleChangeUserDetail({ image })
  }

  const handleOnChangeInput = key => e => {
    handleChangeUserDetail({ [key]: e.target.value })
  }

  const handleOnChangeLanguageDropdown = value => {
    setLanguage(value)
  }

  const handleSetUpdatedUser = updatedUser => {
    const { image, name, language, phone } = updatedUser

    setImage(image)
    dispatch(authReducerActions.updateUser({ image, name, language, phone }))
  }

  const handleUpdateLanguage = () => {
    const outdatedLanguage = getActiveProjectLanguage()

    if (outdatedLanguage !== language) {
      setActiveLanguage(language)
      setTimeout(() => {
        window.location.reload()
      }, 400)
    }
  }

  const handleUpdateMe = async () => {
    try {
      setIsSubmitting(true)

      const updatedUser = await updateMe(userDetail)
      handleSetUpdatedUser(updatedUser.data.user)

      toast.success(t('userSettings.success.save'))

      handleUpdateLanguage()
    } catch (err) {
      toast.error(t('userSettings.error.general'))
    } finally {
      setIsSubmitting(false)
    }
  }

  const renderLanguageItem = item => {
    return (
      <div className='user-settings__language-item' key={item.value}>
        <img src={item.icon} alt={item.label} className='user-settings__language-icon' />
        <span>{item.label}</span>
      </div>
    )
  }

  const handleRenderLanguageDropdownItem = item => renderLanguageItem(item)

  const handleRenderLanguageDropdownSelectedItemLabel = selectedItem => renderLanguageItem(selectedItem)

  useEffect(() => {
    const { image, language, name, phone } = authUserDetail

    setImage(image)
    setUserDetail({ image, language, name, phone })
  }, [])

  const languageSelectedItemIndex = languagesItems.findIndex(languageItem => languageItem.value === language)

  return (
    <div className='user-settings__list'>
      <div className='user-settings__item'>
        <div className='user-settings__label'>{t('userSettings.label.photo')}</div>
        <div className='user-settings__content user-settings__content--image'>
          <UserAvatar image={image} size={PROFILE_IMAGE_SIZES.XXL} />
          <input className='user-settings__image-input' id='user_image' type='file' onChange={handleChangeImage} accept='image/*' />
          <label htmlFor='user_image' className='user-settings__change-image'>
            {t('userSettings.button.change')}
          </label>
        </div>
      </div>
      <div className='user-settings__item user-settings__item--disabled'>
        <div className='user-settings__label'>{t('userSettings.label.email')}</div>
        <div className='user-settings__content'>
          <Input className='user-settings__input' value={authUserDetail.email} />
        </div>
      </div>
      <div className='user-settings__item'>
        <div className='user-settings__label'>{t('userSettings.label.name')}</div>
        <div className='user-settings__content'>
          <Input onChange={handleOnChangeInput('name')} className='user-settings__input' value={userDetail.name} />
        </div>
      </div>
      <div className='user-settings__item'>
        <div className='user-settings__label'>{t('userSettings.label.language')}</div>
        <div className='user-settings__content'>
          <InputDropdown
            className='user-settings__input'
            items={languagesItems}
            handleChange={handleOnChangeLanguageDropdown}
            selectedItemIndex={languageSelectedItemIndex}
            renderItem={handleRenderLanguageDropdownItem}
            renderSelectedItemLabel={handleRenderLanguageDropdownSelectedItemLabel}
            isEnableDeleteItemIcon={false}
          />
        </div>
      </div>
      <div className='user-settings__item'>
        <div className='user-settings__label'>{t('userSettings.label.phone')}</div>
        <div className='user-settings__content'>
          <Input onChange={handleOnChangeInput('phone')} className='user-settings__input' value={userDetail.phone} />
        </div>
      </div>
      <div className='user-settings__item'>
        <UserAdvancedSettings />
      </div>
      {authUserDetail.register_type === USER_REGISTER_TYPES.INAVO_ACCOUNT && (
        <div className='user-settings__item'>
          <div className='user-settings__inavo-account-logo-container'>
            <img src={InavoAccountBlackLogo} alt='Inavo Account Logo' className='user-settings__inavo-account-logo' />
            <span className='user-settings__inavo-account-logo-text'>{t('userSettings.text.registeredWith')}</span>
          </div>
        </div>
      )}
      <div className='user-settings__item'>
        <PrimaryButton onClick={handleUpdateMe} isLoading={isSubmitting} className='user-settings__save-btn'>
          {t('userSettings.button.save')}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default UserSettings
