import { clsx } from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { BiChevronDown } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { postDeleteAccount } from '@/api'
import ContinueAskPopup from '@/components/ContinueAskPopup/ContinueAskPopup'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import useLogout from '@/hooks/useLogout'

import './userAdvancedSettings.scss'

const UserAdvancedSettings = () => {
  const { t } = useTranslation()
  const [isEnableUserAdvancedSettings, setIsEnableUserAdvancedSettings] = useState(false)
  const [isDeletingAccount, setIsDeletingAccount] = useState(false)
  const [isEnabledDeleteAccountContinuePopup, setIsEnabledDeleteAccountContinuePopup] = useState(false)

  const navigate = useNavigate()
  const { logoutFromClient } = useLogout()

  const handleClickUserAdvancedSettingsButton = () => {
    setIsEnableUserAdvancedSettings(!isEnableUserAdvancedSettings)
  }

  const handleDeleteAccount = async () => {
    try {
      setIsDeletingAccount(true)

      await postDeleteAccount()
      logoutFromClient()

      navigate('/login')
    } catch (err) {
      toast.error(t('userAdvancedSettings.error.general'))
    } finally {
      setIsDeletingAccount(false)
    }
  }

  const handleOnClickDeleteAccount = () => {
    setIsEnabledDeleteAccountContinuePopup(true)
  }

  const handleCloseDeleteAccountContinuePopup = () => {
    setIsEnabledDeleteAccountContinuePopup(false)
  }

  return (
    <div className='user-advanced-settings'>
      <ContinueAskPopup
        handleClosePopup={handleCloseDeleteAccountContinuePopup}
        title={t('userAdvancedSettings.deleteAccount.title')}
        isVisible={isEnabledDeleteAccountContinuePopup}
        noButtonProps={{ onClick: handleCloseDeleteAccountContinuePopup }}
        yestButtonProps={{ onClick: handleDeleteAccount }}>
        {t('userAdvancedSettings.deleteAccount.description')}
      </ContinueAskPopup>
      <div onClick={handleClickUserAdvancedSettingsButton} className='user-advanced-settings__button'>
        <div className='user-advanced-settings__button-text'>{t('userAdvancedSettings.button.advancedSettings')}</div>
        <BiChevronDown className='user-advanced-settings__button-icon' />
      </div>
      <div className={clsx('user-advanced-settings__list', { 'user-advanced-settings__list--enable': isEnableUserAdvancedSettings })}>
        <OutlinedButton
          isLoading={isDeletingAccount}
          onClick={handleOnClickDeleteAccount}
          className='user-advanced-settings__item user-advanced-settings__delete-account'>
          {t('userAdvancedSettings.button.deleteAccount')}
        </OutlinedButton>
      </div>
    </div>
  )
}

export default UserAdvancedSettings
