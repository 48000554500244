import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { BsFillHeartFill } from 'react-icons/bs'

import './copyright.scss'

const Copyright = () => {
  const { t } = useTranslation()

  const currentYear = dayjs().format('YYYY')

  return (
    <div className='copyright'>
      <span className='copyright__text'>{t('copyright.madeIn')} </span>
      <BsFillHeartFill className='copyright__heart' />
      <span className='copyright__text'>
        {t('copyright.developedWith')} {currentYear}
      </span>
    </div>
  )
}

export default Copyright
