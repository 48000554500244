import { useTranslation } from 'react-i18next'

import InavoIcon from '@/assets/logos/inavo.svg'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './errorPage.scss'

const ErrorPage = ({ resetError }) => {
  const { t } = useTranslation()

  const handleOnClickRetry = () => {
    resetError()
  }

  return (
    <div className='error-page'>
      <div className='error-page__content'>
        <img src={InavoIcon} className='error-page__icon' />
        <div className='error-page__text'>{t('errorPage.message')}</div>
        <PrimaryButton className='error-page__retry-button' onClick={handleOnClickRetry}>
          {t('errorPage.button.retry')}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default ErrorPage
