export default {
  'test.lang': 'test eng',
  'postmanBuilder.back': 'back',
  'postmanBuilder.dataStep.error.process': 'An error occurred while processing the collection.',
  'postmanBuilder.dataStep.title': 'Postman Builder',
  'postmanBuilder.dataStep.selectCollection': 'Select a collection',
  'postmanBuilder.manageActions.title': 'Manage Actions',
  'postmanBuilder.completeProcess.error.general': 'An error occurred',
  'postmanBuilder.completeProcess.success.title': 'Success!',
  'postmanBuilder.completeProcess.success.redirecting': 'Redirecting...',
  'postmanBuilder.completeProcess.info': 'Everything is ready! After this step, your actions will be automatically created.',
  'postmanBuilder.completeProcess.button.create': 'Create Now',
  'postmanBuilder.completeProcess.title': 'Everything is ready.',
  'postmanBuilder.completeProcess.button.continue': 'Create',
  'postmanBuilder.adjustDefaultVariables.defaultHeader': 'Default Header',
  'postmanBuilder.adjustDefaultVariables.defaultBody': 'Default Body',
  'postmanBuilder.adjustDefaultVariables.defaultUrlQueryParams': 'Default URL Query Parameters',
  'postmanBuilder.adjustDefaultVariables.title': 'Manage Default Values',
  'postmanBuilder.askQuestions.title': 'One more step...',
  'postmanBuilder.askQuestions.baseUrl.title': 'Base URL Information',
  'postmanBuilder.askQuestions.baseUrl.placeholder': 'Ex: https://exp.com/api',
  'postmanBuilder.askQuestions.baseUrl.text': 'I have a base URL named',
  'postmanBuilder.askQuestions.baseUrlKey.placeholder': 'Ex: base_url',
  'postmanBuilder.askQuestions.baseUrlKey.text': 'I use it as base URL in Postman with key name',
  'postmanBuilder.step.brand.console': 'console',
  'postmanBuilder.step.brand.postman': 'postman',
  'postmanBuilder.step.button.back': 'Back',
  'postmanBuilder.step.button.continue': 'Continue',
  'postmanBuilder.step.info.existingActions':
    'You already have actions. With Postman integration, new actions will be added except for existing ones.',
  'actionMenuList.noActions': 'No actions added yet',
  'actionMenuList.searchPlaceholder': 'Search actions...',
  'actionMenuList.noFilteredActions': 'No actions found',
  'apiInformation.url.label': 'Url*',
  'apiInformation.url.dynamicField': "Start with ':' for dynamic field",
  'apiInformation.url.tooltip': 'Parameters that are added as dependencies and added by default with field type url can be used',
  'apiInformation.method.label': 'Method*',
  'apiInformation.method.placeholder': 'Select method...',
  'apiInformation.test.button': 'Test',
  'apiInformationResult.error': 'An error occurred',
  'apiInformationResultPopup.title': 'Result',
  'authenticationFlow.highlight.simple': 'Simple.',
  'authenticationFlow.highlight.easy': 'Easy.',
  'authenticationFlow.highlight.functional': 'Functional.',
  'colorPicker.placeholder': 'Select color',
  'continueAskPopup.button.no': 'No',
  'continueAskPopup.button.yes': 'Yes',
  'dynamicChart.filter.button': 'Filter',
  'dynamicChart.filter.apply': 'Apply',
  'editTable.toast.deleteSuccess': 'Successfully deleted!',
  'editTable.toast.error': 'An error occurred',
  'editTable.toast.updateSuccess': 'Successfully updated!',
  'editTable.delete.title': 'You are about to delete',
  'editTable.delete.description': 'Are you sure you want to delete?',
  'errorPage.message': 'An error occurred, we are looking into it.',
  'errorPage.button.retry': 'Reload',
  'fileInput.button.upload': 'Upload',
  'fileInput.noFiles': 'Nothing has been uploaded yet',
  'fileInput.preview.title': 'Preview',
  'fileInput.preview.unknownFile': 'Unknown file',
  'fileUpload.error.type': 'Invalid file type',
  'fileUpload.error.size': 'Invalid file size',
  'fileUpload.dropHere': 'Drop here',
  'fileUpload.dragOrUpload': 'Drag & drop or upload',
  'richTextEditor.clickToEdit': 'Click to edit',
  'richTextEditor.popup.title': 'Edit',
  'richTextEditor.popup.save': 'Save',
  'input.noData': 'No data',
  'input.search': 'Search..',
  'jsonEditor.error.invalid': 'Invalid JSON',
  'manageActions.noValue': 'No value',
  'manageActions.selectedCount': '{count} selected actions',
  'multipleInputList.noItem': 'Nothing has been added',
  'multipleInputList.addButton': 'Add',
  'offlinePage.message': 'You appear to be offline, please check your internet connection.',
  'permissionError.title': 'Unauthorized Area',
  'permissionError.description':
    'You do not have permission to access this area. If you think this is an error, please contact your administrator.',
  'permissionError.backHome': 'Go to Home',
  'table.action': 'Action',
  'table.totalResult': 'Total {count} data',
  'table.noData': 'No data',
  'table.addButton': 'Add',
  'widgetFallBackError.message': 'An error occurred',
  'buildApi.error.general': 'An error occurred',
  'buildApi.formPage.dragHere': 'Drag here from left panel',
  'buildApi.leftPanel.title': 'Form Elements',
  'buildApi.leftPanel.menu.basic': 'BASIC',
  'buildApi.leftPanel.menu.advanced': 'ADVANCED',
  'buildApi.rightPanel.settings': 'Settings',
  'buildApi.rightPanel.fieldSettings.menu.general': 'GENERAL',
  'buildApi.rightPanel.fieldSettings.menu.advanced': 'ADVANCED',
  'buildApi.rightPanel.fieldSettings.defaultValue.placeholder': 'Select default value...',
  'buildApi.rightPanel.fieldSettings.noChoices': 'No choices added yet',
  'buildApi.rightPanel.fieldSettings.selectPhoto': 'Select Photo',
  'buildApi.rightPanel.fieldSettings.toggleSwitch.on': 'On',
  'buildApi.rightPanel.fieldSettings.toggleSwitch.off': 'Off',
  'buildApi.rightPanel.fieldSettings.date.placeholder': 'Select a date...',
  'buildApi.rightPanel.fieldSettings.time.placeholder': 'Select a time...',
  'buildApi.rightPanel.fieldSettings.defaultValue.input.placeholder': 'Default Value',
  'buildApi.rightPanel.fieldSettings.key.title': 'Key*',
  'buildApi.rightPanel.fieldSettings.key.tooltip': 'Unique value',
  'buildApi.rightPanel.fieldSettings.key.placeholder': 'Key',
  'buildApi.rightPanel.fieldSettings.title.label': 'Title',
  'buildApi.rightPanel.fieldSettings.title.placeholder': 'Title',
  'buildApi.rightPanel.fieldSettings.description': 'Description',
  'buildApi.rightPanel.fieldSettings.type.title': 'Type*',
  'buildApi.rightPanel.fieldSettings.type.placeholder': 'Select type...',
  'buildApi.rightPanel.fieldSettings.defaultValue.title': 'Default Value',
  'buildApi.rightPanel.fieldSettings.rules': 'Rules',
  'buildApi.rightPanel.fieldSettings.hide': 'Hide',
  'buildApi.rightPanel.fieldSettings.exclude.title': 'Exclude',
  'buildApi.rightPanel.fieldSettings.exclude.tooltip': 'Exclude from API',
  'buildApi.rightPanel.fieldSettings.dependency': 'Dependency Status',
  'buildApi.rightPanel.fieldSettings.description.type.external': 'External',
  'buildApi.rightPanel.fieldSettings.description.type.hint': 'Hint',
  'buildApi.rightPanel.fieldSettings.description.placeholder': 'Write something...',
  'buildApi.rightPanel.fieldSettings.rules.allRules': 'All Rules',
  'buildApi.rightPanel.fieldSettings.rules.popup.title': 'All Rules',
  'buildApi.rightPanel.fieldSettings.rules.noRules': 'No rules added yet',
  'buildApi.rightPanel.fieldSettings.rules.addRule': 'Add Rule',
  'buildApi.rightPanel.fieldSettings.rules.back': 'Back',
  'buildApi.rightPanel.fieldSettings.rules.selectRule': 'Select a rule',
  'buildApi.rightPanel.fieldSettings.rules.added': 'Added',
  'buildApi.rightPanel.fieldSettings.rules.save': 'Save',
  'buildApi.rightPanel.fieldSettings.rules.value': 'Value',
  'buildApi.top.toast.updateSuccess': 'Action updated successfully',
  'buildApi.top.toast.createSuccess': 'Action created successfully',
  'buildApi.top.toast.error': 'An error occurred',
  'buildApi.top.confirm.unsavedChanges': 'Changes are not saved yet, are you sure you want to continue?',
  'buildApi.top.back': 'back',
  'buildApi.top.title.placeholder': 'Enter title',
  'buildApi.top.goWithoutSaving': 'go without saving',
  'buildApi.top.save': 'Save',
  'buildApi.settings.toast.deleteSuccess': 'Action deleted successfully',
  'buildApi.settings.toast.error': 'An error occurred',
  'buildApi.settings.popup.title': 'Settings',
  'buildApi.settings.delete.title': 'Are you sure you want to delete?',
  'buildApi.settings.delete.description': 'This action cannot be undone after deletion.',
  'buildApi.settings.delete.button': 'Delete Action',
  'buildApi.editTable.advanced.title': 'Title',
  'buildApi.editTable.advanced.dataKey.title': 'Data Key',
  'buildApi.editTable.advanced.dataKey.tooltip':
    'If the data returned from the URL is nested in another JSON, its key value should be written (e.g., "resData", "resData.scopedData")',
  'buildApi.editTable.advanced.dynamicPage.title': 'API Dynamic Pagination Status',
  'buildApi.editTable.advanced.dynamicPage.tooltip':
    'If your data comes in chunks, you need to set Limit Key and Total Key for pagination.',
  'buildApi.editTable.advanced.columns': 'Table Columns',
  'buildApi.editTable.advanced.actions': 'Actions',
  'buildApi.editTable.advanced.filters': 'Filters',
  'buildApi.editTable.advanced.other': 'Other',
  'buildApi.editTable.advanced.styles': 'Styles',
  'buildApi.editTable.advanced.styles.height': 'Height',
  'buildApi.editTable.advanced.dynamicPage.limitKey': 'Limit Key',
  'buildApi.editTable.advanced.dynamicPage.limitKeyTooltip': 'Key value of the limit parameter to be used for pagination',
  'buildApi.editTable.advanced.dynamicPage.totalKey': 'Total Key',
  'buildApi.editTable.advanced.dynamicPage.totalKeyTooltip': 'Key value where the total data count is located',
  'buildApi.apiSettings.menu.general': 'GENERAL',
  'buildApi.apiSettings.menu.api': 'API',
  'buildApi.apiSettings.menu.advanced': 'ADVANCED',
  'buildApi.apiSettings.bodyType.formData': 'Form Data',
  'buildApi.apiSettings.bodyType.formUrlEncoded': 'Form URL Encoded',
  'buildApi.apiSettings.bodyType.none': 'None',
  'buildApi.apiSettings.general.group': 'Group',
  'buildApi.apiSettings.general.groupPlaceholder': 'Select group...',
  'buildApi.apiSettings.general.description': 'Description',
  'buildApi.apiSettings.general.descriptionPlaceholder': 'Enter description...',
  'buildApi.apiSettings.general.confirmationWindow': 'Confirmation Window',
  'buildApi.apiSettings.api.completeButtonStatus': 'Complete Button Status',
  'buildApi.apiSettings.api.completeButtonDisabledInfo': 'Complete button is disabled',
  'buildApi.apiSettings.api.completeButtonEnabledInfo': 'Complete button is active',
  'buildApi.apiSettings.api.disableCompleteButton': 'Disable Complete Button',
  'buildApi.apiSettings.api.urlDynamicFieldPrefix': "Start with ':' for dynamic field",
  'buildApi.apiSettings.api.urlDynamicFieldTooltip':
    'Parameters that are added as dependencies and added by default with field type url can be used',
  'buildApi.apiSettings.api.method': 'Method',
  'buildApi.apiSettings.apiGroup.errors.updateFailed': 'An error occurred while updating the group',
  'buildApi.apiSettings.apiGroup.errors.saveFailed': 'An error occurred while saving the group',
  'buildApi.apiSettings.apiGroup.errors.deleteFailed': 'An error occurred while deleting the group',
  'buildApi.apiSettings.apiGroup.actionCount': '{count} actions',
  'buildApi.apiSettings.apiGroup.title': 'API Groups',
  'buildApi.apiSettings.apiGroup.groupNamePlaceholder': 'Enter group name...',
  'buildApi.apiSettings.apiGroup.save': 'Save',
  'buildApi.apiSettings.confirmation.defaultTitle': 'Are you sure you want to do this action?',
  'buildApi.apiSettings.confirmation.defaultDescription': 'This action cannot be undone.',
  'buildApi.apiSettings.confirmation.title': 'Title',
  'buildApi.apiSettings.confirmation.titlePlaceholder': 'Enter title...',
  'buildApi.apiSettings.confirmation.description': 'Description',
  'buildApi.apiSettings.confirmation.descriptionPlaceholder': 'Enter description...',
  'buildApi.apiSettings.api.header': 'Header',
  'buildApi.apiSettings.api.defaultBody': 'Default Body',
  'buildApi.apiSettings.api.defaultUrlQueryParams': 'Default URL Query Parameters',
  'buildApi.apiSettings.api.otherFields': 'Other Fields',
  'buildApi.apiSettings.api.bodyType': 'Body Type',
  'buildApi.apiSettings.api.methodPlaceholder': 'Select method...',
  'buildApi.apiSettings.api.headers': 'Default Headers',
  'buildApi.apiSettings.advanced.otherFields': 'Other',
  'buildApi.field.type.string': 'Short Text',
  'buildApi.field.type.textarea': 'Long Text',
  'buildApi.field.type.number': 'Number',
  'buildApi.field.type.password': 'Password',
  'buildApi.field.type.select': 'Dropdown List',
  'buildApi.field.type.remoteSelect': 'Dynamic Dropdown List',
  'buildApi.field.type.image': 'Photo',
  'buildApi.field.type.richText': 'Rich Text Editor',
  'buildApi.field.type.toggleSwitch': 'Toggle Switch',
  'buildApi.field.type.date': 'Date Picker',
  'buildApi.field.type.hour': 'Time Picker',
  'buildApi.field.type.file': 'File Upload',
  'buildApi.field.type.colorPicker': 'Color Picker',
  'buildApi.field.type.beautifyJson': 'JSON Editor',
  'buildApi.field.type.editTable': 'Table Editor',
  'buildApi.field.type.unknown': 'Unknown Element',
  'buildApi.field.rule.required': 'Required Field',
  'buildApi.field.rule.min': 'Minimum Value',
  'buildApi.field.rule.max': 'Maximum Value',
  'buildApi.field.rule.unknown': 'Unknown Rule',
  'buildApi.field.error': 'An error occurred',
  'buildApi.field.label.placeholder': 'Write something...',
  'buildApi.field.type.unknownField': 'Unknown field type',
  'buildApi.field.notIncluded': 'Not included',
  'buildApi.field.previewEditTable': 'Table Editor',
  'buildApi.submitButton': 'Complete',
  'buildApi.toggleSwitch.advanced.activeValue.title': 'Active Value',
  'buildApi.toggleSwitch.advanced.activeValue.placeholder': 'Enter active value...',
  'buildApi.toggleSwitch.advanced.passiveValue.title': 'Passive Value',
  'buildApi.toggleSwitch.advanced.passiveValue.placeholder': 'Enter passive value...',
  'buildApi.select.advanced.options.title': 'Options',
  'buildApi.select.advanced.options.label': 'Label',
  'buildApi.select.advanced.options.value': 'Value',
  'buildApi.remoteSelect.advanced.dataKey.title': 'Data Key',
  'buildApi.remoteSelect.advanced.dataKey.placeholder': 'Enter data key...',
  'buildApi.remoteSelect.advanced.labelKey.title': 'Label Key',
  'buildApi.remoteSelect.advanced.labelKey.placeholder': 'Enter label key...',
  'buildApi.remoteSelect.advanced.valueKey.title': 'Value Key',
  'buildApi.remoteSelect.advanced.valueKey.placeholder': 'Enter value key...',
  'buildApi.remoteSelect.advanced.searchParam.title': 'Search Parameter',
  'buildApi.remoteSelect.advanced.searchParam.placeholder': 'Enter search parameter...',
  'buildApi.remoteSelect.advanced.dependencies.title': 'Dependencies',
  'buildApi.remoteSelect.advanced.dependencies.key': 'Key',
  'buildApi.remoteSelect.advanced.dependencies.type': 'Type',
  'buildApi.remoteSelect.advanced.header.title': 'Header',
  'buildApi.file.advanced.multipleUpload.title': 'Multiple Upload',
  'buildApi.file.advanced.supportedTypes.title': 'Supported File Types',
  'buildApi.file.advanced.onlyAccept.photo': 'Photo',
  'buildApi.file.advanced.onlyAccept.video': 'Video',
  'buildApi.file.advanced.onlyAccept.audio': 'Audio',
  'buildApi.file.advanced.onlyAccept.all': 'All',
  'buildApi.editTable.advanced.sections.title': 'Title',
  'buildApi.editTable.advanced.sections.dataKeyTooltip':
    'If the data returned from the URL is nested in another JSON, its key value should be written (e.g., "resData", "resData.scopedData")',
  'buildApi.editTable.advanced.sections.dynamicPaging': 'API Dynamic Pagination Status',
  'buildApi.editTable.advanced.sections.dynamicPagingTooltip':
    'If your data comes in chunks, you need to set Limit Key and Total Key for pagination.',
  'buildApi.editTable.advanced.sections.tableColumns': 'Table Columns',
  'buildApi.editTable.advanced.sections.actions': 'Actions',
  'buildApi.editTable.advanced.sections.filters': 'Filters',
  'buildApi.editTable.advanced.sections.other': 'Other',
  'buildApi.editTable.advanced.sections.styles': 'Styles',
  'buildApi.editTable.advanced.other.emptyDataWrapper': 'Show empty area if no data',
  'buildApi.editTable.advanced.filters.popup.allFilters': 'All Filters',
  'buildApi.editTable.advanced.filters.popup.newFilter': 'New Filter',
  'buildApi.editTable.advanced.filters.popup.editFilter': 'Edit Filter',
  'buildApi.editTable.advanced.filters.popup.addNewFilter': 'Add New Filter',
  'buildApi.editTable.advanced.filters.popup.noFilters': 'No filters added yet',
  'buildApi.editTable.advanced.filters.edit.back': 'Back',
  'buildApi.editTable.advanced.filters.edit.title': 'Title',
  'buildApi.editTable.advanced.filters.edit.key': 'Key',
  'buildApi.editTable.advanced.filters.edit.remoteSource': 'Remote Source',
  'buildApi.editTable.advanced.filters.edit.save': 'Save',
  'buildApi.editTable.advanced.filters.list.title': 'Title',
  'buildApi.editTable.advanced.filters.list.actions': 'Actions',
  'buildApi.editTable.advanced.columns.viewAll': 'View All Columns',
  'buildApi.editTable.advanced.columns.popup.allColumns': 'All Columns',
  'buildApi.editTable.advanced.columns.popup.newColumn': 'New Column',
  'buildApi.editTable.advanced.columns.popup.editColumn': 'Edit Column',
  'buildApi.editTable.advanced.columns.popup.addNewColumn': 'Add New Column',
  'buildApi.editTable.advanced.columns.popup.noColumns': 'No columns added yet',
  'buildApi.editTable.advanced.columns.edit.back': 'Back',
  'buildApi.editTable.advanced.columns.edit.title': 'Title',
  'buildApi.editTable.advanced.columns.edit.dataIndex': 'Data Index',
  'buildApi.editTable.advanced.columns.edit.other': 'Other',
  'buildApi.editTable.advanced.columns.edit.isEditable': 'Is Editable(Currently only text field)',
  'buildApi.editTable.advanced.columns.edit.isUsableOnUrl': 'Is Usable On URL',
  'buildApi.editTable.advanced.columns.edit.save': 'Save',
  'buildApi.editTable.advanced.columns.list.columnTitle': 'Column Title',
  'buildApi.editTable.advanced.columns.list.columnName': 'Column Name',
  'buildApi.editTable.advanced.columns.list.actions': 'Actions',
  'buildApi.editTable.advanced.actions.viewAll': 'View All Actions',
  'buildApi.editTable.advanced.actions.popup.allActions': 'All Actions',
  'buildApi.editTable.advanced.actions.popup.editAdd': 'Edit Add Action',
  'buildApi.editTable.advanced.actions.popup.editUpdate': 'Edit Update Action',
  'buildApi.editTable.advanced.actions.popup.editDelete': 'Edit Delete Action',
  'buildApi.editTable.advanced.actions.edit.back': 'Back',
  'buildApi.editTable.advanced.actions.edit.urlDynamicFieldPrefix': "Start with ':' for dynamic field",
  'buildApi.editTable.advanced.actions.edit.urlDynamicFieldTooltip':
    'Parameters that are added as dependencies and added by default with field type url can be used',
  'buildApi.editTable.advanced.actions.edit.method': 'Method',
  'buildApi.editTable.advanced.actions.edit.methodPlaceholder': 'Select method...',
  'buildApi.editTable.advanced.actions.edit.bodyType': 'Body Type',
  'buildApi.editTable.advanced.actions.edit.bodyType.formData': 'Form Data',
  'buildApi.editTable.advanced.actions.edit.bodyType.formUrlEncoded': 'Form URL Encoded',
  'buildApi.editTable.advanced.actions.edit.bodyType.none': 'None',
  'buildApi.editTable.advanced.actions.edit.defaultBody': 'Default Body',
  'buildApi.editTable.advanced.actions.edit.defaultBodyKeys': 'Default Body Keys',
  'buildApi.editTable.advanced.actions.edit.delete': 'Delete',
  'buildApi.editTable.advanced.actions.edit.save': 'Save',
  'buildApi.editTable.advanced.actions.list.addAction': 'Add Action',
  'buildApi.editTable.advanced.actions.list.addActionDetails': 'Edit add action details',
  'buildApi.editTable.advanced.actions.list.updateAction': 'Update Action',
  'buildApi.editTable.advanced.actions.list.updateActionDetails': 'Edit update action details',
  'buildApi.editTable.advanced.actions.list.deleteAction': 'Delete Action',
  'buildApi.editTable.advanced.actions.list.deleteActionDetails': 'Edit delete action details',
  'buildApi.editTable.advanced.actions.list.edited': 'Edited',
  'buildApi.editTable.advanced.filters.viewAll': 'View All Filters',
  'changePassword.input.newPassword.placeholder': 'New password',
  'changePassword.input.repeatNewPassword.placeholder': 'Repeat new password',
  'changePassword.button.changePassword': 'Change Password',
  'changePassword.toast.error.passwordMatch': 'Passwords must match',
  'changePassword.toast.success': 'Password changed successfully',
  'changePassword.toast.error': 'An error occurred',
  'forgetPassword.title': 'Forgot Password',
  'forgetPassword.input.email.placeholder': 'Email',
  'forgetPassword.button.send': 'Send',
  'forgetPassword.link.login': 'Have an account? Login',
  'forgetPassword.success.title': 'Success',
  'forgetPassword.success.description': 'We sent an email to {email}.',
  'forgetPassword.success.login': 'Login now',
  'forgetPassword.toast.error.emailNotFound': 'This email is not registered in the system',
  'forgetPassword.toast.error': 'An error occurred',
  'login.title': 'Login Now!',
  'login.input.email.placeholder': 'E-mail',
  'login.input.password.placeholder': 'Password',
  'login.link.forgetPassword': 'Forgot password',
  'login.button.login': 'Login',
  'login.text.or': 'or',
  'login.button.inavoAccount': 'Login with inavo Account',
  'login.link.register': "Don't have an account? Register",
  'login.toast.error.invalidCredentials': 'Invalid email or password',
  'login.toast.error': 'An error occurred, we are working on it.',
  'register.title': 'Register Now!',
  'register.input.name.placeholder': 'Name*',
  'register.input.email.placeholder': 'Email*',
  'register.input.password.placeholder': 'Password*',
  'register.button.register': 'Register',
  'register.link.login': 'Have an account? Login',
  'register.toast.success': 'Successfully registered',
  'register.toast.error.userExists': 'This user is already registered',
  'register.toast.error': 'An error occurred',
  'closeButton.error': 'An error occurred',
  'projectSwitcher.searchPlaceholder': 'Search projects...',
  'projectSwitcher.noProjects': 'No projects found',
  'projectSwitcher.createProject': 'Create Project',
  'userUpdatePassword.success.update': 'Password successfully changed',
  'userUpdatePassword.success.logout': 'Logged out from other accounts',
  'userUpdatePassword.error.wrongPassword': 'Current password is incorrect',
  'userUpdatePassword.error.general': 'An error occurred',
  'userUpdatePassword.label.currentPassword': 'Current Password',
  'userUpdatePassword.label.newPassword': 'New Password',
  'userUpdatePassword.label.repeatNewPassword': 'Repeat New Password',
  'userUpdatePassword.button.update': 'Update',
  'generalMenuList.title': 'GENERAL',
  'generalMenuList.home': 'Home',
  'copyright.madeIn': 'Made in Turkey',
  'copyright.developedWith': 'with developed © ',
  'createProject.error.general': 'An error occurred',
  'createProject.title': 'Create Project',
  'createProject.label.name': 'Project Name*',
  'createProject.label.url': 'URL*',
  'createProject.label.description': 'Description',
  'createProject.button.create': 'Create',
  'menuList.module': 'MODULE',
  'menuList.charts': 'Charts',
  'menuList.tables': 'Tables',
  'menuList.project': 'PROJECT',
  'menuList.users': 'Users',
  'menuList.actions': 'Actions',
  'menuList.logs': 'Logs',
  'menuList.notifications': 'Notifications',
  'menuList.settings': 'Settings',
  'menuList.permission': 'PERMISSION',
  'menuList.userPermissions': 'User Permissions',
  'menuList.actionPermissions': 'Action Permissions',
  'menuList.account': 'ACCOUNT',
  'menuList.userProfile': 'User Profile',
  'menuList.accountSettings': 'Account Settings',
  'noProjects.text': 'No projects created yet',
  'noProjects.button.create': 'Create Now',
  'search.home': 'Home',
  'search.updateSettings': 'Update Settings',
  'search.updateSettings.description': 'You can update project settings on this page',
  'search.users': 'Users',
  'search.users.description': 'You can view project users on this page',
  'search.addUser': 'Add User',
  'search.addUser.description': 'You can add project users on this page',
  'search.updateUser': 'Update User',
  'search.updateUser.description': 'You can update project users on this page',
  'search.deleteUser': 'Delete User',
  'search.deleteUser.description': 'You can delete project users on this page',
  'search.logs': 'Logs',
  'search.logs.description': 'You can view project logs on this page',
  'search.projectPermissions': 'Project Permissions',
  'search.projectPermissions.description': 'You can view project permissions on this page',
  'search.updateProjectPermission': 'Update Project Permission',
  'search.updateProjectPermission.description': 'You can update project permissions on this page',
  'search.actionPermissions': 'Action Permissions',
  'search.actionPermissions.description': 'You can view action permissions on this page',
  'search.deleteActionPermission': 'Delete Action Permission',
  'search.deleteActionPermission.description': 'You can delete action permissions on this page',
  'search.addActionPermission': 'Add Action Permission',
  'search.addActionPermission.description': 'You can add action permissions on this page',
  'search.updateActionPermission': 'Update Action Permission',
  'search.updateActionPermission.description': 'You can update action permissions on this page',
  'search.charts': 'Charts',
  'search.charts.description': 'You can view charts on this page',
  'search.createAction': 'Create Action',
  'search.createAction.description': 'You can create actions on this page',
  'search.tables': 'Tables',
  'search.tables.description': 'You can view tables on this page',
  'search.addTable': 'Add Table',
  'search.addTable.description': 'You can add tables on this page',
  'search.deleteTable': 'Delete Table',
  'search.deleteTable.description': 'You can delete tables on this page',
  'search.updateTable': 'Update Table',
  'search.updateTable.description': 'You can update tables on this page',
  'search.minCharacter': 'At least 1 character required',
  'search.noResults': 'No results found',
  'search.placeholder': 'Search...',
  'userSettings.success.save': 'Successfully saved',
  'userSettings.error.general': 'An error occurred',
  'userSettings.label.photo': 'Photo',
  'userSettings.button.change': 'Change',
  'userSettings.label.email': 'E-mail',
  'userSettings.label.name': 'Name',
  'userSettings.label.language': 'Language',
  'userSettings.label.phone': 'Phone',
  'userSettings.text.registeredWith': 'registered with',
  'userSettings.button.save': 'Save',
  'userAdvancedSettings.error.general': 'An error occurred',
  'userAdvancedSettings.deleteAccount.title': 'Are you sure you want to delete your account?',
  'userAdvancedSettings.deleteAccount.description': 'Are you sure?',
  'userAdvancedSettings.button.advancedSettings': 'Advanced Settings',
  'userAdvancedSettings.button.deleteAccount': 'Delete Account',
  'header.button.createAction': 'Create Action',
  'header.button.accountSettings': 'Account Settings',
  'header.button.changePassword': 'Change Password',
  'header.button.userProfile': 'User Profile',
  'header.button.documentation': 'Documentation',
  'header.button.logout': 'Logout',
  'userSettingsPopup.title': 'Account Settings',
  'userUpdatePasswordPopup.title': 'Change Password',
  'mobileRoot.leftMenu.logout': 'Logout',
  'mobileRoot.allApis.title': 'ACTIONS',
  'mobileRoot.userSettings.updateProfile': 'Update Profile',
  'mobileRoot.userSettings.changePassword': 'Change Password',
  'serviceError.message': 'We are currently experiencing issues with our services. We are working on it.',
  'withRootLogic.error.sessionExpired': 'Your session has expired. Please login again.',
  'withRootLogic.error.general': 'An error occurred',
  'header.button.createApi': 'Create Action',
  'buildApi.top.preview': 'Save & Preview',
  'actionGroupPermissions.edit': 'edit',
  'actionGroupPermissions.delete': 'delete',
  'actionGroupPermissions.searchPlaceholder': 'Search by name...',
  'actionGroupPermissions.addButton': 'Add Action Permission Group',
  'actionGroupPermissions.noGroups': 'No action permission groups added yet',
  'actionGroupPermissions.noFilteredGroups': 'No action permission groups for this filter',
  'actionGroupPermissions.error.general': 'An error occurred',
  'actionGroupPermissions.form.alreadyAdmin': 'Already admin',
  'actionGroupPermissions.form.saveButton': 'Save',
  'actionGroupPermissions.form.addButton': 'Add',
  'actionGroupPermissions.form.editTitle': 'Edit Action Permission Group',
  'actionGroupPermissions.form.addTitle': 'Add Action Permission Group',
  'actionGroupPermissions.detail.groupName': 'Group Name',
  'actionGroupPermissions.detail.actions': 'Actions',
  'actionGroupPermissions.detail.users': 'Users',
  'actionGroupPermissions.dropdown.noItems': 'No items',
  'actionGroupPermissions.dropdown.noFilteredItems': 'No items for this filter',
  'actionGroupPermissions.form.error.emptyName': 'Group name cannot be empty',
  'actionGroupPermissions.form.error.noActions': 'At least one action must be added',
  'actionGroupPermissions.form.error.noUsers': 'At least one user must be added',
  'actionGroupPermissions.form.success': 'Operation completed successfully',
  'actionGroupPermissions.form.label.groupName': 'Group Name*',
  'actionGroupPermissions.form.placeholder.groupName': 'Enter group name...',
  'actionGroupPermissions.form.label.actions': 'Actions',
  'actionGroupPermissions.form.placeholder.addAction': '+ Add Action',
  'actionGroupPermissions.form.noActionsToAdd': 'No actions to add',
  'actionGroupPermissions.form.noFilteredActions': 'No actions for this filter',
  'actionGroupPermissions.form.noAddedActions': 'No actions added yet',
  'actionGroupPermissions.form.label.users': 'Users',
  'actionGroupPermissions.form.placeholder.addUser': '+ Add User',
  'actionGroupPermissions.form.noUsersToAdd': 'No users to add',
  'actionGroupPermissions.form.noFilteredUsers': 'No users for this filter',
  'actionGroupPermissions.form.noAddedUsers': 'No users added yet',
  'actionGroupPermissions.delete.title': 'Are you sure you want to delete?',
  'actions.update.success': 'Actions updated successfully',
  'actions.order.success': 'Action orders updated successfully',
  'actions.groups.order.success': 'Action group orders updated successfully',
  'actions.no.actions': 'No actions',
  'actions.change.action.order': 'Change Action Order',
  'actions.change.group.order': 'Change Group Order',
  'actions.edit.titles': 'Edit Titles',
  'actions.mobile.information': 'You can quickly access all actions by tapping the central icon at the bottom of the screen.',
  'actions.title': 'Actions',
  'actions.move.select.group': 'Select Group',
  'actions.move.select.action': 'Select Action',
  'moveActions.back': 'Back',
  'actions.cancel': 'Cancel',
  'actions.save': 'Save',
  'apiExecute.steps.firstForm': 'First Form',
  'apiExecute.steps.actionForm': 'Action Form',
  'apiExecute.error.general': 'An error occurred',
  'apiExecute.error.unknownSideContent': 'Unknown side content type',
  'apiExecute.ruleErrors.title': 'Errors',
  'apiExecute.ruleErrors.count': '{count} errors found',
  'apiExecute.moreInfo.title': 'Detail',
  'apiExecute.moreInfo.label.title': 'Title',
  'apiExecute.moreInfo.label.group': 'Group',
  'apiExecute.moreInfo.label.description': 'Description',
  'apiExecute.moreInfo.label.url': 'URL',
  'apiExecute.moreInfo.label.type': 'Type',
  'apiExecute.moreInfo.label.actionHeader': 'Action Header',
  'apiExecute.moreInfo.label.actionBody': 'Action Body',
  'apiExecute.moreInfo.label.defaultActionBody': 'Default Action Body',
  'apiExecute.moreInfo.label.actionUrlBody': 'Action URL Body',
  'apiExecute.moreInfo.label.defaultActionUrlBody': 'Default Action URL Body',
  'apiExecute.moreInfo.label.generalFormElements': 'General Form Elements',
  'apiExecute.moreInfo.label.settings': 'Settings',
  'apiExecute.moreInfo.label.actionOtherBodies': 'Others',
  'apiExecute.header.errorCount': '{count} errors',
  'apiExecute.logs.error.fetch': 'An error occurred while fetching action logs',
  'apiExecute.logs.detail.status.success': 'successful',
  'apiExecute.logs.detail.status.error': 'error',
  'apiExecute.logs.detail.tab.data': 'data',
  'apiExecute.logs.detail.tab.header': 'header',
  'apiExecute.logs.detail.tab.other': 'other',
  'apiExecute.logs.detail.tab.settings': 'settings',
  'apiExecute.logs.detail.tab.status': 'status',
  'apiExecute.logs.detail.title': 'Detail',
  'apiExecute.logs.detail.requestData': 'Request Data:',
  'apiExecute.logs.detail.result': 'Result:',
  'apiExecute.logs.noHistory': 'No history',
  'apiExecute.logs.loadMore': 'Load More',
  'apiExecute.initialValues.error': 'An error occurred',
  'apiExecute.initialValues.continue': 'Continue',
  'apiExecute.submitButton.submit': 'Submit',
  'apiExecute.submitButton.success': 'Operation completed successfully',
  'apiExecute.submitButton.error': 'An error occurred',
  'charts.error.fetch': 'An error occurred',
  'charts.noCharts': 'No charts added yet',
  'charts.title': 'Charts',
  'charts.createChart': 'Create Chart',
  'charts.form.edit.title': 'Edit Chart',
  'charts.form.create.title': 'Create Chart',
  'charts.form.delete.success': 'Chart successfully deleted',
  'charts.form.error.general': 'An error occurred',
  'charts.form.update.success': 'Chart successfully updated',
  'charts.form.create.success': 'Chart successfully created',
  'charts.form.label.title': 'Title',
  'charts.form.placeholder.title': 'Title*',
  'charts.form.label.method': 'Method',
  'charts.form.placeholder.method': 'Select a method*',
  'charts.form.label.url': 'Url',
  'charts.form.label.dataKey': 'Data Key',
  'charts.form.tooltip.dataKey':
    'If the data returned from the URL is nested in another JSON, its key value should be written (e.g., "resData", "resData.scopedData")',
  'charts.form.placeholder.dataKey': 'Data Key',
  'charts.form.label.apiHeader': 'API Header',
  'charts.form.label.chartType': 'Chart Type',
  'charts.form.button.back': 'Back',
  'charts.form.delete.title': 'Are you sure you want to delete?',
  'charts.form.delete.description':
    'This chart will be deleted even if it is used as a widget. Please check the homepage after the operation is completed.',
  'charts.form.button.delete': 'Delete',
  'charts.form.button.save': 'Save',
  'charts.form.chartType.sampleOutput': 'Sample Output',
  'charts.form.chartType.sampleOutputDescription':
    'To use this chart type, the URL you specified should return a response similar to the one below.',
  'chooseCreateApiType.manual.title': 'Create Manually',
  'chooseCreateApiType.manual.description': 'Create manually',
  'chooseCreateApiType.import.title': 'Import',
  'chooseCreateApiType.import.description': 'Import collection',
  'chooseCreateApiType.postman.title': 'Postman Builder',
  'chooseCreateApiType.postman.description': 'Integrate with an existing Postman collection',
  'chooseCreateApiType.button.home': 'Home',
  'chooseCreateApiType.title': 'Create a new action',
  'home.welcome': 'Welcome',
  'home.error.general': 'An error occurred',
  'home.widget.deleted': 'This widget has been deleted',
  'home.widget.delete': 'delete',
  'home.widget.add': 'Add',
  'home.widget.noWidgets': 'No widgets added yet',
  'home.button.cancel': 'Cancel',
  'home.button.save': 'Save',
  'home.button.edit': 'Edit',
  'home.widgetGuide.add.title': 'Add',
  'home.widgetGuide.add.chart': 'Chart',
  'home.widgetGuide.add.or': 'or',
  'home.widgetGuide.add.table': 'Table',
  'home.widgetGuide.add.description': 'you can add.',
  'home.widgetGuide.edit.title': 'Edit',
  'home.widgetGuide.edit.description': 'You can position the chart or table you added anywhere on the home page using drag/drop.',
  'home.widgetGuide.ready.title': 'Ready',
  'home.widgetGuide.ready.description': 'The home page will stay as you arranged it.',
  'home.dragAndDrop.unknownWidget': 'Unknown Widget',
  'home.dragAndDrop.noWidgets': 'No widgets available to add',
  'loginWithAccount.error.message': 'An error occurred...',
  'loginWithAccount.button.loginPage': 'Login Page',
  'loginWithAccount.loading.message': 'Logging in',
  'notFoundError.title': '404 | Looks like you got lost.',
  'notFoundError.button.home': 'Go to Home',
  'projectLogs.filters.date.placeholder': 'Select Date',
  'projectLogs.filters.orderBy.fromTodayToPast': 'From today to past',
  'projectLogs.filters.orderBy.fromPastToToday': 'From past to today',
  'projectLogs.filters.type.placeholder': 'Select type',
  'projectLogs.filters.user.placeholder': 'Select user',
  'projectLogs.filters.button.clear': 'Clear',
  'projectLogs.filters.button.apply': 'Apply',
  'projectLogs.filters.title': 'Filters',
  'projectLogs.tag.createdProjectUser': 'User Added',
  'projectLogs.tag.deletedProjectUser': 'User Deleted',
  'projectLogs.tag.leaveUserFromProject': 'Left Project',
  'projectLogs.tag.createdProjectApiPermission': 'Action Permission Added',
  'projectLogs.tag.createdApi': 'Action Added',
  'projectLogs.tag.createdApiCollection': 'Action Exported',
  'projectLogs.tag.executedApiCollection': 'Action Imported',
  'projectLogs.tag.deletedApi': 'Action Deleted',
  'projectLogs.tag.createdChart': 'Chart Added',
  'projectLogs.tag.createdTable': 'Table Added',
  'projectLogs.tag.deletedChart': 'Chart Deleted',
  'projectLogs.tag.deletedProjectApiPermission': 'Action Permission Deleted',
  'projectLogs.tag.deletedTable': 'Table Deleted',
  'projectLogs.tag.updatedProjectApiPermission': 'Action Permission Updated',
  'projectLogs.tag.updatedProject': 'Project Updated',
  'projectLogs.tag.updatedChart': 'Chart Updated',
  'projectLogs.tag.updatedTable': 'Table Updated',
  'projectLogs.tag.executedApi': 'Action Executed',
  'projectLogs.tag.userLogged': 'User Logged In',
  'projectLogs.tag.general': 'General',
  'projectLogs.error.getProjectLogs': 'An error occurred',
  'projectLogs.noLogs': 'No logs',
  'projectLogs.moreButton': 'More',
  'projectLogs.title': 'Project Logs',
  'projectSettings.menu.settings': 'Settings',
  'projectSettings.menu.advancedSettings': 'Advanced Settings',
  'projectSettings.menu.others': 'Others',
  'projectSettings.title': 'Project Settings',
  'projectSettings.general.toast.success': 'Information updated',
  'projectSettings.general.toast.error': 'An error occurred',
  'projectSettings.general.button.change': 'Change',
  'projectSettings.general.label.name': 'Project Name',
  'projectSettings.general.error.required': 'Cannot be empty',
  'projectSettings.general.placeholder.name': 'My Project',
  'projectSettings.general.label.description': 'Description',
  'projectSettings.general.placeholder.description': 'Write something',
  'projectSettings.general.button.save': 'Save',
  'projectSettings.advanced.toast.success': 'Information updated',
  'projectSettings.advanced.toast.error': 'An error occurred',
  'projectSettings.advanced.label.baseColor': 'Base Color',
  'projectSettings.advanced.info.noColor': 'No color entered',
  'projectSettings.advanced.label.url': 'URL',
  'projectSettings.advanced.error.required': 'Cannot be empty',
  'projectSettings.advanced.error.invalidJson': 'Invalid JSON',
  'projectSettings.advanced.label.apiDefaultHeaders': 'API Default Headers',
  'projectSettings.advanced.label.apiDefaultBody': 'API Default Body',
  'projectSettings.advanced.label.apiDefaultUrlQueryParams': 'API Default URL Query Params',
  'projectSettings.advanced.label.configs': 'Configs',
  'projectSettings.advanced.button.save': 'Save',
  'projectSettings.other.import.title': 'Import',
  'projectSettings.other.import.description': 'You can import the action collection.',
  'projectSettings.other.import.button': 'Import Actions',
  'projectSettings.other.export.title': 'Export',
  'projectSettings.other.export.description': 'You can export all existing actions as a JSON file.',
  'projectSettings.other.export.button': 'Export Actions',
  'projectSettings.other.delete.title': 'Delete Project',
  'projectSettings.other.delete.description': 'You can completely delete the project. The project cannot be recovered after deletion',
  'projectSettings.other.delete.button': 'Delete Project',
  'projectSettings.delete.popup.title': 'Delete Project',
  'projectSettings.delete.popup.description': 'You are about to delete the project. To continue with deletion, you must write "{text}".',
  'projectSettings.delete.popup.button.continue': 'Continue',
  'projectSettings.delete.popup.warning':
    'The project cannot be recovered after deletion. You are at the final stage of deleting the project now. Are you sure you want to delete?',
  'projectSettings.delete.popup.button.cancel': 'Cancel',
  'projectSettings.delete.popup.button.confirm': 'Yes, Delete',
  'projectSettings.delete.toast.success': 'Project successfully deleted',
  'projectSettings.delete.toast.redirect': 'Redirecting...',
  'projectSettings.delete.toast.error': 'An error occurred',
  'projectSettings.export.popup.title': 'Export Actions',
  'projectSettings.export.popup.button.prepare': 'Prepare!',
  'projectSettings.export.toast.error': 'An error occurred',
  'projectSettings.export.home.description': 'Welcome to the action export wizard! You can easily export your selected actions.',
  'projectSettings.export.download.loading': 'Your file is being prepared...',
  'projectSettings.export.download.description':
    'Your file is ready! If the download did not start automatically, you can use the button below.',
  'projectSettings.export.download.button': 'Download',
  'projectSettings.export.variables.description': 'Edit default variables for actions to be exported.',
  'projectSettings.export.variables.header.title': 'Default Header',
  'projectSettings.export.variables.body.title': 'Default Body',
  'projectSettings.export.variables.queryParams.title': 'Default URL Query Params',
  'projectSettings.export.variables.configs.title': 'Configs',
  'projectSettings.import.popup.title': 'Import Actions',
  'projectSettings.import.popup.button.import': 'Import',
  'projectSettings.import.toast.error': 'An error occurred',
  'projectSettings.import.upload.label': 'Upload or drag and drop a collection',
  'projectSettings.import.upload.error.invalidJson': 'Invalid JSON structure',
  'projectSettings.import.variables.header.title': 'Default Header',
  'projectSettings.import.variables.body.title': 'Default Body',
  'projectSettings.import.variables.queryParams.title': 'Default URL Query Params',
  'projectSettings.import.variables.configs.title': 'Configs',
  'projectSettings.import.success.message': 'Operation completed successfully. Redirecting to homepage...',
  'projectSettings.delete.popup.confirmationText': 'i want to delete the project',
  'popup.button.back': 'Back',
  'popup.button.next': 'Next',
  'projectUserImprint.title': 'User Imprint',
  'projectUserImprint.item.title.joinedDate': 'Project Join Date',
  'projectUserImprint.toast.error': 'An error occurred',
  'projectUserLeave.popup.title': 'Are you sure you want to leave the project?',
  'projectUserLeave.popup.description': 'This action cannot be undone after leaving the project.',
  'projectUserLeave.button.leaveProject': 'Leave Project',
  'projectUserLeave.toast.error': 'An error occurred',
  'projectUserNotifications.title': 'Project Notifications',
  'projectUserNotifications.noNotifications': 'No notifications',
  'projectUserPermissions.title': 'Project Permissions',
  'projectUserPermissions.noProjectUsers': 'No project users',
  'projectUserPermissions.filter.placeholder': 'Search by email...',
  'projectUserPermissions.toast.error': 'An error occurred',
  'projectUserPermissions.backButton': 'Back',
  'tables.createTable': 'Create Table',
  'tables.noTable': 'No tables added',
  'tables.edit': 'edit',
  'tables.save': 'Save',
  'tables.delete': 'Delete',
  'tables.deletePopup.confirmationText': 'Are you sure you want to delete?',
  'tables.deletePopup.description':
    'This table will be deleted even if it is used as a widget. Please check the homepage after the operation is completed.',
  'tables.label': 'Title',
  'tables.labelPlaceholder': 'Title',
  'tables.method': 'Select a method*',
  'tables.methodPlaceholder': 'Select a method*',
  'tables.url': 'Url*',
  'tables.dataKey': 'Data Key',
  'tables.paginationStatus': 'API Dynamic Pagination Status',
  'tables.totalKey': 'Total Key*',
  'tables.limitKey': 'Limit Key*',
  'tables.columns': 'Table Columns*',
  'tables.otherSettings': 'Other',
  'tables.back': 'Back',
  'tables.dataKeyPlaceholder': 'Data Key',
  'tables.emptyDataWrapper': 'Write "-" character instead of empty data',
  'tables.limitKeyTooltip': 'Limit key for pagination',
  'tables.paginationStatusTooltip': 'API Dynamic Pagination Status',
  'tables.dataKeyTooltip':
    'If the data returned from the url you specified is returned in another json, its key value should be written (e.g. "resData", "resData.scopedData")',
  'tables.totalKeyTooltip': 'Total count key for pagination',
  'tables.totalKeyPlaceholder': 'Total Key*',
  'users.deletePopup.confirmationText': 'User cannot be deleted',
  'users.toast.error': 'An error occurred',
  'users.title': 'Users',
  'users.filter.placeholder': 'Search users by email...',
  'users.addUserButton': 'Add User',
  'users.noProjectUsers': 'No users added',
  'users.addUserPopup.title': 'Add User',
  'users.addUserPopup.emailPlaceholder': 'User E-mail',
  'users.addUserPopup.addButton': 'Add',
  'users.deletePopup.successMessage': 'You left the project',
  'users.deletePopup.removeUserSuccessMessage': 'User deleted',
  'users.deletePopup.leaveProjectConfirmationText': 'Are you sure you want to leave the project?',
  'users.deletePopup.removeUserConfirmationText': 'Are you sure you want to delete?',
  'users.deletePopup.leaveProjectDescriptionText': 'You are about to remove yourself from the project.',
  'users.deletePopup.yesLeaveProjectButton': 'Yes, leave',
  'users.toast.validationError': 'Invalid email address',
  'users.toast.success': 'Operation completed successfully',
  'dynamicFieldErrors.required': 'This field is required',
  'dynamicFieldErrors.max': '{number} is greater than {number}',
  'dynamicFieldErrors.min': '{number} is less than {number}',
  'dynamicFieldErrors.unknownError': 'Unknown error',
  unknownFile: 'Unknown File Name',
  'userType.admin': 'Admin',
  'userType.member': 'Member',
  'buildApi.topUndoHistory.undo': 'undo',
  'buildApi.topUndoHistory.redo': 'redo',
}
