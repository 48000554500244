import clsx from 'clsx'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Input from '@/components/Input/Input'
import { filterText as filterTextUtil } from '@/utils'

import './search.scss'

const Search = () => {
  const { t } = useTranslation()
  const [filterText, setFilterText] = useState('')
  const [isEnabledDropdown, setIsEnabledDropdown] = useState(false)

  const navigate = useNavigate()

  const SEARCH_ITEMS = [
    {
      title: t('search.home'),
      description: t('search.home'),
      redirectUrl: '/',
    },
    {
      title: t('search.updateSettings'),
      description: t('search.updateSettings.description'),
      redirectUrl: '/project-settings',
    },
    {
      title: t('search.users'),
      description: t('search.users.description'),
      redirectUrl: '/users',
    },
    {
      title: t('search.addUser'),
      description: t('search.addUser.description'),
      redirectUrl: '/users',
    },
    {
      title: t('search.updateUser'),
      description: t('search.updateUser.description'),
      redirectUrl: '/users',
    },
    {
      title: t('search.deleteUser'),
      description: t('search.deleteUser.description'),
      redirectUrl: '/users',
    },
    {
      title: t('search.logs'),
      description: t('search.logs.description'),
      redirectUrl: '/project-logs',
    },
    {
      title: t('search.projectPermissions'),
      description: t('search.projectPermissions.description'),
      redirectUrl: '/project-user-permissions',
    },
    {
      title: t('search.updateProjectPermission'),
      description: t('search.updateProjectPermission.description'),
      redirectUrl: '/project-user-permissions',
    },
    {
      title: t('search.actionPermissions'),
      description: t('search.actionPermissions.description'),
      redirectUrl: '/action-group-permissions',
    },
    {
      title: t('search.deleteActionPermission'),
      description: t('search.deleteActionPermission.description'),
      redirectUrl: '/action-group-permissions',
    },
    {
      title: t('search.addActionPermission'),
      description: t('search.addActionPermission.description'),
      redirectUrl: '/action-group-permissions',
    },
    {
      title: t('search.updateActionPermission'),
      description: t('search.updateActionPermission.description'),
      redirectUrl: '/action-group-permissions',
    },
    {
      title: t('search.charts'),
      description: t('search.charts.description'),
      redirectUrl: '/charts',
    },
    {
      title: t('search.createAction'),
      description: t('search.createAction.description'),
      redirectUrl: '/build-api',
    },
    {
      title: t('search.tables'),
      description: t('search.tables.description'),
      redirectUrl: '/tables',
    },
    {
      title: t('search.addTable'),
      description: t('search.addTable.description'),
      redirectUrl: '/tables',
    },
    {
      title: t('search.deleteTable'),
      description: t('search.deleteTable.description'),
      redirectUrl: '/tables',
    },
    {
      title: t('search.updateTable'),
      description: t('search.updateTable.description'),
      redirectUrl: '/tables',
    },
  ]

  const isEmptyFilterText = filterText === ''
  const filteredItems = SEARCH_ITEMS.filter(
    ({ title, description }) => filterTextUtil(filterText, title) || filterTextUtil(filterText, description),
  )
  const hasFilteredItems = filteredItems.length > 0

  const handleOnFocus = () => {
    setIsEnabledDropdown(true)
  }

  const handleOnBlur = () => {
    setIsEnabledDropdown(false)
  }

  const handleOnChange = e => {
    setFilterText(e.target.value)
  }

  const handleOnClickItem = redirectUrl => () => {
    navigate(redirectUrl)
    setFilterText('')
  }

  const getContent = () => {
    switch (true) {
      case isEmptyFilterText:
        return <div className='header-search__no-filter-text'>{t('search.minCharacter')}</div>
      case !hasFilteredItems:
        return <div className='header-search__no-filter-text'>{t('search.noResults')}</div>
      default:
        return (
          <>
            {filteredItems.map(({ title, description, redirectUrl }, key) => (
              <div onClick={handleOnClickItem(redirectUrl)} key={key} className='header-search__item'>
                <div className='header-search__title'>{title}</div>
                <div className='header-search__description'>{description}</div>
              </div>
            ))}
          </>
        )
    }
  }

  return (
    <div className='header-search'>
      <Input
        value={filterText}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        className='header-search__input'
        placeholder={t('search.placeholder')}
      />
      <div className={clsx('header-search__dropdown', { 'header-search__dropdown--enabled': isEnabledDropdown })}>{getContent()}</div>
    </div>
  )
}

export default Search
