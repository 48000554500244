import { FaChartBar } from 'react-icons/fa'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { LuTable } from 'react-icons/lu'
import { PiHeartBold, PiKeyBold, PiUserBold, PiUsersThreeBold } from 'react-icons/pi'
import { RiUserSettingsLine } from 'react-icons/ri'
import { TbDeviceImacSearch } from 'react-icons/tb'
import { TbSettings } from 'react-icons/tb'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ROUTE_URLS } from '@/constants/routeUrls'
import MenuItemList from '@/containers/Root/components/common/MenuItemList/MenuItemList'
import usePermission from '@/hooks/usePermission'
import { appReducerActions } from '@/store/reducers/app'
import { isMobile } from '@/utils'

import './menuList.scss'

const MenuList = ({ handleClickMenuItem }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { isProjectAdmin, checkPagePermission } = usePermission()

  const dispatch = useDispatch()

  const checkActiveItem = path => location.pathname === path

  const handleClickAccountSettingsItem = () => {
    dispatch(appReducerActions.setIsEnabledUserSettingsDropdown(true))
  }

  // TODO: we should consider MENU_LIST permissions again.

  const MENU_LIST = [
    ...(checkPagePermission(ROUTE_URLS.CHARTS) || checkPagePermission(ROUTE_URLS.TABLES)
      ? [
          {
            title: t('menuList.module'),
            items: [
              ...(checkPagePermission(ROUTE_URLS.CHARTS)
                ? [
                    {
                      path: ROUTE_URLS.CHARTS,
                      Icon: FaChartBar,
                      text: t('menuList.charts'),
                    },
                  ]
                : []),
              ...(checkPagePermission(ROUTE_URLS.TABLES)
                ? [
                    {
                      path: ROUTE_URLS.TABLES,
                      Icon: LuTable,
                      text: t('menuList.tables'),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    {
      title: t('menuList.project'),
      items: [
        ...(checkPagePermission(ROUTE_URLS.USERS)
          ? [
              {
                path: ROUTE_URLS.USERS,
                Icon: PiUsersThreeBold,
                text: t('menuList.users'),
              },
            ]
          : []),
        {
          path: ROUTE_URLS.ACTIONS,
          Icon: PiHeartBold,
          text: t('menuList.actions'),
        },
        ...(checkPagePermission(ROUTE_URLS.PROJECT_LOGS)
          ? [
              {
                path: ROUTE_URLS.PROJECT_LOGS,
                Icon: TbDeviceImacSearch,
                text: t('menuList.logs'),
              },
            ]
          : []),
        {
          path: ROUTE_URLS.PROJECT_USER_NOTIFICATIONS,
          Icon: IoMdNotificationsOutline,
          text: t('menuList.notifications'),
        },
        ...(checkPagePermission(ROUTE_URLS.PROJECT_SETTINGS)
          ? [
              {
                path: ROUTE_URLS.PROJECT_SETTINGS,
                Icon: TbSettings,
                text: t('menuList.settings'),
              },
            ]
          : []),
      ],
    },
    ...(isProjectAdmin
      ? [
          {
            title: t('menuList.permission'),
            items: [
              ...(checkPagePermission(ROUTE_URLS.PROJECT_USER_PERMISSIONS)
                ? [
                    {
                      path: ROUTE_URLS.PROJECT_USER_PERMISSIONS,
                      Icon: RiUserSettingsLine,
                      text: t('menuList.userPermissions'),
                    },
                  ]
                : []),
              ...(checkPagePermission(ROUTE_URLS.ACTION_GROUP_PERMISSIONS)
                ? [
                    {
                      path: ROUTE_URLS.ACTION_GROUP_PERMISSIONS,
                      Icon: PiKeyBold,
                      text: t('menuList.actionPermissions'),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isMobile()
      ? []
      : [
          {
            title: t('menuList.account'),
            items: [
              {
                path: ROUTE_URLS.PROJECT_USER_IMPRINT,
                Icon: PiUserBold,
                text: t('menuList.userProfile'),
              },
              {
                Icon: RiUserSettingsLine,
                text: t('menuList.accountSettings'),
                onClick: handleClickAccountSettingsItem,
              },
            ],
          },
        ]),
  ].map(({ items, ...payload }) => ({
    ...payload,
    items: items.map(item => ({
      ...item,
      isActive: checkActiveItem(item.path),
    })),
  }))

  return <MenuItemList menuList={MENU_LIST} handleClickMenuItem={handleClickMenuItem} menuListWrapperClassName='menu-list__wrapper' />
}

export default MenuList
