import { FaGooglePlay } from 'react-icons/fa'
import { IoIosAppstore } from 'react-icons/io'
import { IoMdClose } from 'react-icons/io'

import { setDownloadAppAdvertShown } from '@/utils/localStorageActions'

import './downloadAppAdvert.scss'

const DownloadAppAdvert = ({ handleCloseDownloadAppAdvert }) => {
  const handleCloseButtonClick = () => {
    setDownloadAppAdvertShown(true)
    handleCloseDownloadAppAdvert()
  }

  return (
    <div className='download-app-advert'>
      <div onClick={handleCloseButtonClick} className='download-app-advert__close-button'>
        <IoMdClose />
      </div>
      <div className='download-app-advert__app-links'>
        <a className='download-app-advert__app-link download-app-advert__app-link--disabled'>
          <FaGooglePlay className='download-app-advert__app-link-icon' />
          <span className='download-app-advert__app-link-text'>Google Play</span>
        </a>
        <a href='https://apps.apple.com/us/app/inavo-console/id1600496320' className='download-app-advert__app-link'>
          <IoIosAppstore className='download-app-advert__app-link-icon' />
          <span className='download-app-advert__app-link-text'>App Store</span>
        </a>
      </div>
    </div>
  )
}

export default DownloadAppAdvert
