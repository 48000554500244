import { useTranslation } from 'react-i18next'

import './serviceError.scss'

const ServiceError = () => {
  const { t } = useTranslation()

  return <div>{t('serviceError.message')}</div>
}

export default ServiceError
