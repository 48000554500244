import { useTranslation } from 'react-i18next'

import InavoIcon from '@/assets/logos/inavo.svg'

import './offlinePage.scss'

const OfflinePage = () => {
  const { t } = useTranslation()

  return (
    <div className='offline-page'>
      <div className='offline-page__content'>
        <img src={InavoIcon} className='offline-page__icon' />
        <div className='offline-page__text'>{t('offlinePage.message')}</div>
      </div>
    </div>
  )
}

export default OfflinePage
