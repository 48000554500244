import { RiForbid2Fill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ROUTE_URLS } from '@/constants/routeUrls'

import './permissionError.scss'

const PermissionError = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleOnClickBackHome = () => {
    navigate(ROUTE_URLS.HOME)
  }

  return (
    <div className='permission-error'>
      <RiForbid2Fill className='permission-error__icon' />
      <div className='permission-error__title'>{t('permissionError.title')}</div>
      <div className='permission-error__desc'>{t('permissionError.description')}</div>
      <div onClick={handleOnClickBackHome} className='permission-error__back-home'>
        {t('permissionError.backHome')}
      </div>
    </div>
  )
}

export default PermissionError
