import { motion } from 'framer-motion'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

import CircleLightLogo from '@/assets/logos/circle-light-logo.svg'
import CloseButton from '@/containers/Root/components/CloseButton/CloseButton'
import Copyright from '@/containers/Root/components/Copyright/Copyright'
import GeneralMenuList from '@/containers/Root/components/GeneralMenuList/GeneralMenuList'
import MenuList from '@/containers/Root/components/MenuList/MenuList'
import ProjectSwitcher from '@/containers/Root/components/ProjectSwitcher/ProjectSwitcher'
import withMenuLogic from '@/containers/Root/withMenuLogic'

import './mobileRootTopLeftMenu.scss'

const MobileRootTopLeftMenu = ({ isVisible, handleCloseMenu, renderContent }) => {
  const { t } = useTranslation()

  const leftMenuProps = {
    className: 'mobile-left-menu',
    initial: false,
    animate: isVisible ? 'open' : 'closed',
    transition: { duration: 0.22 },
    variants: {
      open: { left: 0 },
      closed: { left: '-100%' },
    },
  }

  const handleClickMenuItem = () => {
    handleCloseMenu()
  }

  return (
    <motion.div {...leftMenuProps}>
      <IoIosCloseCircleOutline onClick={handleCloseMenu} className='mobile-left-menu__close' />
      <div className='mobile-left-menu__content'>
        {renderContent(
          <>
            <img src={CircleLightLogo} className='mobile-left-menu__brand' />
            <div className='mobile-left-menu__item'>
              <ProjectSwitcher />
            </div>
            <div className='mobile-left-menu__item'>
              <GeneralMenuList handleClickMenuItem={handleClickMenuItem} />
            </div>
            <div className='mobile-left-menu__item'>
              <MenuList handleClickMenuItem={handleClickMenuItem} />
            </div>
            <div className='mobile-left-menu__item'>
              <Copyright />
            </div>
          </>,
        )}
        <div className='mobile-left-menu__item mobile-left-menu__item--close'>
          <CloseButton>
            <span className='mobile-left-menu__close-button-content'>{t('mobileRoot.leftMenu.logout')}</span>
          </CloseButton>
        </div>
      </div>
    </motion.div>
  )
}

export default withMenuLogic(MobileRootTopLeftMenu)
