import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { postLogout } from '@/api'
import InavoLoading from '@/components/InavoLoading/InavoLoading'
import { ROUTE_URLS } from '@/constants/routeUrls'
import useLogout from '@/hooks/useLogout'

import './closeButton.scss'

const CloseButton = ({ children }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const { logoutFromClient } = useLogout()

  const handleOnClickCloseButton = async () => {
    setIsLoading(true)
    try {
      await postLogout()
      logoutFromClient()

      navigate(ROUTE_URLS.LOGIN)
    } catch (err) {
      toast.error(t('closeButton.error'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div onClick={handleOnClickCloseButton} className={clsx('close-button', { 'close-button--loading': isLoading })}>
      {children}
      {isLoading && <InavoLoading className='close-button__loading-icon' />}
    </div>
  )
}

export default CloseButton
