import { useTranslation } from 'react-i18next'

import Popup from '@/components/Popup/Popup'
import UserSettings from '@/containers/Root/components/UserSettings/UserSettings'

import './userSettingsPopup.scss'

const UserSettingsPopup = ({ isVisible, handleClosePopup }) => {
  const { t } = useTranslation()

  return (
    <Popup
      title={t('userSettingsPopup.title')}
      modalClassName='user-settings-popup__modal'
      isVisible={isVisible}
      handleClosePopup={handleClosePopup}>
      <UserSettings />
    </Popup>
  )
}

export default UserSettingsPopup
