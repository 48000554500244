export const API_STATUSES = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const PROJECT_LOG_TYPES = {
  CREATED_PROJECT_USER: 'created_project_user',
  DELETED_PROJECT_USER: 'deleted_project_user',
  LEAVE_USER_FROM_PROJECT: 'leave_user_from_project',
  CREATED_PROJECT_API_PERMISSION: 'created_project_api_permission',
  UPDATED_PROJECT_API_PERMISSION: 'updated_project_api_permission',
  DELETED_PROJECT_API_PERMISSION: 'deleted_project_api_permission',
  UPDATED_PROJECT: 'updated_project',
  EXECUTED_API: 'executed_api',
  USER_LOGGED: 'user_logged',
  CREATED_API: 'created_api',
  CREATED_API_COLLECTION: 'created_api_collection',
  EXECUTED_API_COLLECTION: 'executed_api_collection',
  DELETED_API: 'deleted_api',
  CREATED_CHART: 'created_chart',
  UPDATED_CHART: 'updated_chart',
  DELETED_CHART: 'deleted_chart',
  CREATED_TABLE: 'created_table',
  UPDATED_TABLE: 'updated_table',
  DELETED_TABLE: 'deleted_table',
}

export const LANGUAGES = {
  TR: 'tr-TR',
  EN: 'en-US',
}

export const DEFAULT_LANGUAGE = LANGUAGES.EN

export const CHART_TYPES = {
  SIMPLE_VALUE: 'simple_value',
  BAR: 'bar',
  AREA: 'area',
  DONUT: 'donut',
}

export const PROJECT_WIDGET_TYPES = {
  CHART: 'chart',
  TABLE: 'table',
}

export const ADVANCED_FIELD_TYPES = {
  EDIT_TABLE: 'edit_table',
}
export const GENERAL_FIELD_TYPES = {
  STRING: 'string',
  TEXTAREA: 'textarea',
  RICH_TEXT: 'rich_text',
  NUMBER: 'number',
  PASSWORD: 'password',
  DATE: 'date',
  HOUR: 'HOUR', // TODO: why it is upper case :((
  TOGGLE_SWITCH: 'toggle_switch',
  SELECT: 'select',
  REMOTE_SELECT: 'remote_select',
  IMAGE: 'image',
  FILE: 'FILE',
  COLOR_PICKER: 'color_picker',
  BEAUTIFY_JSON: 'beautify_json',
}

// Sorting of them is important on left menu of build API page
export const FIELD_TYPES = {
  ...GENERAL_FIELD_TYPES,
  ...ADVANCED_FIELD_TYPES,
}

export const ADVANCED_FIELD_TYPE_VALUES = Object.values(ADVANCED_FIELD_TYPES)
export const GENERAL_FIELD_TYPE_VALUES = Object.values(GENERAL_FIELD_TYPES)

export const FIELD_TYPE_VALUES = [...GENERAL_FIELD_TYPE_VALUES, ...ADVANCED_FIELD_TYPE_VALUES]

export const FIELD_GROUP_TYPES = {
  GENERAL: 'general',
  ADVANCED: 'advanced',
}

export const FIELD_RULE_TYPES = {
  REQUIRED: 'required',
  MIN: 'min',
  MAX: 'max',
}

export const FIELD_RULES = {
  [FIELD_TYPES.STRING]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.TEXTAREA]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.RICH_TEXT]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.NUMBER]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
    {
      type: FIELD_RULE_TYPES.MIN,
    },
    {
      type: FIELD_RULE_TYPES.MAX,
    },
  ],
  [FIELD_TYPES.PASSWORD]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.DATE]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.HOUR]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.TOGGLE_SWITCH]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.SELECT]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.REMOTE_SELECT]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.IMAGE]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.FILE]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.COLOR_PICKER]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
  [FIELD_TYPES.BEAUTIFY_JSON]: [
    {
      type: FIELD_RULE_TYPES.REQUIRED,
    },
  ],
}

// It is used for build API. So it is important to valid method of API while Postman Integration
export const API_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}

export const FIELD_SCOPE_TYPES = {
  URL: 'url',
  BODY: 'body',
  GENERAL: 'general',
}

export const ORDER_BYS = {
  DESC: 'desc',
  ASC: 'asc',
}

export const INAVO_COLOR = '#00FF00'

export const BODY_TYPE_CONFIGS = {
  FORM_DATA: 'isFormData',
  FORM_URL_ENCODED: 'isFormUrlEncoded',
  NONE: 'none',
}

export const INAVO_DEV_URL = 'https://www.inavo.dev'

export const USER_REGISTER_TYPES = {
  NORMAL: 'normal',
  INAVO_ACCOUNT: 'inavo_account',
}
