import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { updatePassword } from '@/api'
import Input from '@/components/Input/Input'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './userUpdatePassword.scss'

const INITIAL_PASSWORDS = { currentPassword: '', newPassword: '', repeatNewPassword: '' }

const UserUpdatePassword = ({ handleAfterUpdatedPassword }) => {
  const { t } = useTranslation()
  const [passwords, setPasswords] = useState(INITIAL_PASSWORDS)
  const [isLoading, setIsLoading] = useState(false)

  const hasEmptyState = Object.values(passwords).some(item => item === '')
  const isSamePassword = passwords.newPassword === passwords.repeatNewPassword

  const handleChangePasswordInput = key => e => setPasswords(prev => ({ ...prev, [key]: e.target.value }))
  const handleSetInitialPasswords = () => setPasswords(INITIAL_PASSWORDS)

  const handleOnClickUpdatePasswordButton = async () => {
    try {
      setIsLoading(true)

      await updatePassword({ password: passwords.newPassword, currentPassword: passwords.currentPassword })

      handleAfterUpdatedPassword && handleAfterUpdatedPassword()
      handleSetInitialPasswords()
      toast.success(t('userUpdatePassword.success.update'))
      setTimeout(() => {
        toast.success(t('userUpdatePassword.success.logout'))
      }, 1000)
    } catch (err) {
      const isWrongPasswordError = err.response.data.message === 'The password is not correct'

      toast.error(isWrongPasswordError ? t('userUpdatePassword.error.wrongPassword') : t('userUpdatePassword.error.general'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='user-update-password'>
      <div className='user-update-password__list'>
        <div className='user-update-password__item'>
          <div className='user-update-password__label'>{t('userUpdatePassword.label.currentPassword')}</div>
          <div className='user-update-password__content'>
            <Input
              type='password'
              className='user-update-password__input'
              onChange={handleChangePasswordInput('currentPassword')}
              value={passwords.currentPassword}
            />
          </div>
        </div>
        <div className='user-update-password__item'>
          <div className='user-update-password__label'>{t('userUpdatePassword.label.newPassword')}</div>
          <div className='user-update-password__content'>
            <Input
              type='password'
              className='user-update-password__input'
              onChange={handleChangePasswordInput('newPassword')}
              value={passwords.newPassword}
            />
          </div>
        </div>
        <div className='user-update-password__item'>
          <div className='user-update-password__label'>{t('userUpdatePassword.label.repeatNewPassword')}</div>
          <div className='user-update-password__content'>
            <Input
              type='password'
              className='user-update-password__input'
              onChange={handleChangePasswordInput('repeatNewPassword')}
              value={passwords.repeatNewPassword}
            />
          </div>
        </div>
        <PrimaryButton
          disable={hasEmptyState || !isSamePassword}
          isLoading={isLoading}
          onClick={handleOnClickUpdatePasswordButton}
          className='user-update-password__update-password'>
          {t('userUpdatePassword.button.update')}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default UserUpdatePassword
